.container {
  position: relative;
  padding: 0 16px;
}

.container + .container {
  margin-top: 8px;
}

@media (min-width: 640px) {
  .container {
    padding: 0 60px;
  }

  .container + .container {
    margin-top: 32px;
  }
}
