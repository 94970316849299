.title {
  margin: 0;
  padding-top: 16px;

  & h1 {
    overflow: hidden;
    max-height: 72px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
  }
}
