.container {
  position: relative;
  margin-top: 10px;
  padding: 20px 15px 32px;
  overflow: hidden;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  box-shadow: 0 1px 0 #e4e4e4;
}

title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.22;
}

.description {
  font-size: 16px;
  line-height: 1.5;
  color: #121212;
}
