.container {
  padding: 16px 0;
  display: flex;
  text-decoration: none;
}

@media (min-width: 640px) {
  .container {
    padding: 10px 0;
    height: auto;
    border-top: none;
  }
}

.rc-offer-container {
  padding-top: 12px;
}
