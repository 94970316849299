.slide {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.slide::after {
  position: absolute;
  inset: 0;
  content: '';
  background-color: var(--overlay-media-banner);
}

.disclaimer {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  padding: 4px 8px;
  width: 100%;
}

.content {
  position: relative;
  z-index: 1;
  padding: 64px 24px;
  text-align: center;
}

.logo-container {
  max-width: 100%;
  height: auto;
}

.logo {
  margin: 0 auto 10px;
  max-width: 150px;
  height: auto;
  max-height: 80px;
}

.link-text {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  text-decoration: none;
  color: white;
}

.link-text:active {
  opacity: 0.5;
}

@media (min-width: 640px) {
  .slide {
    padding: 96px 40px 0;
  }

  .content {
    padding: 0 15px;
  }

  .logo {
    max-width: 170px;
  }

  .link-text {
    font-size: 36px;
    line-height: 48px;
  }
}
