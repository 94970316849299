.container {
  margin: 10px;
  background-color: #fef8ed;
  border: 1px solid rgba(255, 157, 0, 0.3);
}

@media (min-width: 640px) {
  .container {
    margin: 32px 60px;
  }
}
