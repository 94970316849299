.container {
  position: relative;
  padding: var(--unit_2, 8px) 46px;
  display: flex;
  height: 180px;
  font-size: var(--fontSize_14px);
  font-weight: 500;
  line-height: var(--lineHeight_20px);
  color: var(--text-inverted-default);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  justify-content: flex-start;
}

.content {
  z-index: 1;
  display: flex;
  max-width: 290px;
  height: fit-content;
}
