.paper {
  position: relative;
  padding: 0 16px;
  overflow: hidden;
  background-color: white;
}

.container {
  position: relative;
  color: #121212;
}

.author {
  padding: 16px 0 12px;
}

.building-status {
  margin-bottom: 4px;
}

.gallery {
  height: 178px;
}

.promo {
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
}

.favorite {
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 4px;
}

.content {
  margin-bottom: 8px;
  padding: 8px 0;
}

.rc-title {
  margin: 0 0 5px;
  padding-right: 24px;
}

.price-limits {
  margin-bottom: 4px;
}

.media {
  position: relative;
  display: block;
}

.carousel {
  position: relative;
}

.features {
  position: absolute;
  z-index: 1;
  left: 15px;
  bottom: 10px;

  &:empty {
    display: none;
  }
}

.labels {
  padding-top: 8px;
  display: flex;
  overflow: auto;
  width: calc(100% + 12px);
  flex-wrap: nowrap;
  gap: 4px;
}

.labels::-webkit-scrollbar {
  display: none;
}

.labels:empty {
  display: none;
}

@media (min-width: 640px) {
  .card {
    display: flex;
    flex-flow: row wrap;
    gap: var(--unit_5);
  }

  .paper {
    margin: 0 60px;
    padding: 0;
  }

  .media {
    display: flex;
    flex-direction: column;
  }

  .carousel {
    flex-grow: 1;
  }

  .gallery {
    height: 100%;
  }

  .thumbnails {
    flex: none;
  }

  .rc-title {
    margin: 0 0 4px;
    padding-right: 0;
  }

  .media {
    flex: 0 0 250px;
    max-width: 250px;
    order: 2;
  }

  .container {
    order: 3;
    flex: 1 1 200px;
  }

  .labels {
    margin-bottom: 6px;
    padding-top: 0;
    display: flex;
    overflow: auto;
    width: 100%;
    flex-wrap: wrap;
    gap: 4px;
  }

  .content {
    margin-bottom: 10px;
    padding: 4px 0 0;
  }

  .controls {
    position: relative;
    padding-top: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .favorite {
    position: static;
    padding: 0;
    display: block;
  }
}
