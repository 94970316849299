.container {
  --image-width: 90px;
  --image-height: 105px;

  position: relative;
  margin: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  /* Не используем сокращенное свойство из-за проблем в Safari 14. */
  background-color: var(--decorative-theme-blue);
  background-image: url('./assets/plates.png');
  background-repeat: no-repeat, no-repeat;
  background-position: top right;
  background-size: var(--image-width) var(--image-height);
  border-radius: 8px;
}

.heading-wrapper {
  max-width: calc(100% - var(--image-width) - 10px);
}

.description-wrapper {
  margin: 4px 0 20px;
}

@media (min-width: 641px) {
  .container {
    margin: 32px 60px;
  }
}
