.link {
  display: flex;
  text-decoration: none;
  align-items: center;
}

.link:hover span,
.link:active span {
  color: #fd5050;
}

.link-star {
  margin: 0 8px 0 0;
  display: flex;
}

.container {
  padding: 12px 14px 0;
}

@media (min-width: 640px) {
  .container {
    padding: 0;
  }
}
