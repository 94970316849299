.container {
  padding: 14px 16px 16px;
  display: flex;
  overflow-y: auto;

  & > * {
    margin-right: 16px;
  }

  & > *:last-child {
    margin-right: 0;
  }

  &:empty {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.container--subtitle {
  justify-content: space-between;
  padding-right: var(--unit_7);
  gap: var(--unit_2);
}

@media (min-width: 640px) {
  .container {
    padding: 14px 60px 16px;
  }
}
