.container {
  padding: 24px 22px 20px;
  text-align: center;
  color: #121212;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.container svg {
  width: 80px;
}

.title {
  margin: 11px 0 4px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.text {
  font-size: 14px;
  line-height: 18px;
}
