.container {
  margin: 10px;
  padding: 16px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  height: 287px;
  text-decoration: none;
  background-color: white;
  background-image: url('./assets/promos-bg.png'), linear-gradient(135deg, #ffdcc8 17.19%, #c1e8fd 87.5%);
  background-repeat: no-repeat;
  background-position:
    bottom left,
    left;
  border-radius: 8px;
}

.title {
  margin-bottom: 4px;
}

.description {
  margin-bottom: 30px;
  white-space: pre-line;
}

.button {
  margin-top: auto;
}

@media (min-width: 640px) {
  .container {
    margin: 32px 60px;
    height: 264px;
    background-position:
      bottom right 22px,
      left;
  }

  .description {
    white-space: normal;
  }
}
