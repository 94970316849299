.container {
  padding-bottom: var(--unit_3);
  display: inline-block;
  width: 100%;
  row-gap: var(--unit_2);
}

.buttons {
  padding-bottom: var(--unit_2);
  display: flex;
  flex-flow: row nowrap;
  gap: var(--unit_2);
}
