.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_1, 4px);
  font-size: var(--fontSize_12px);
  font-weight: 600;
  line-height: var(--lineHeight_16px);
}
