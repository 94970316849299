.container {
  position: relative;
  margin-right: 4px;
  display: flex;
  width: calc(100% - 45px);
  min-width: 100px;
  min-height: 200px;
  flex: 0 0 auto;
  scroll-snap-align: center;

  & > div {
    width: 100%;
    height: 100%;
  }

  &:first-child {
    margin-left: 12px;
  }

  &:last-child {
    margin-right: 12px;
  }

  &.container--fullwidth {
    width: calc(100% - 20px);
  }
}

@media (min-width: 641px) {
  .container {
    margin-right: 12px;
    width: calc(100% - 120px);

    &.container--fullwidth {
      width: calc(100% - 120px);
    }

    & > div {
      min-width: 152px;
    }

    &:first-child {
      margin-left: 60px;
    }

    &:last-child {
      margin-right: 60px;
    }
  }
}
