.banner {
  all: unset;
  margin-top: 10px;
}

.content {
  margin: 8px 12px;
  padding: 12px 16px;
  display: flex;
  height: 86px;
  background: linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  align-items: center;
  border-radius: 8px;
  gap: 20px;
}

.coin {
  width: 48px;
  height: 46px;
  background: url('./assets/coin.svg');
}

@media (min-width: 640px) {
  .content {
    margin: 32px 60px;
  }
}
