.list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li {
  margin: 0;
  padding: 0;
  color: var(--gray60_100);
}
