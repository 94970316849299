.container {
  margin: 10px 10px 0;
  text-align: center;
}

.banner {
  margin-bottom: 12px;
  padding: 24px 47px 20px;
  font-family: Lato;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.banner-image {
  margin: 0 auto 20px;
  width: 100px;
  height: 81px;
  background: url('./assets/subscribe.svg') no-repeat center;
  background-size: contain;
}

.text-bold {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.text {
  margin: 6px 0 0;
  font-size: 14px;
  line-height: 18px;
}

.mobile-button {
  padding: 13px 0 15px;
  width: 100%;
  height: 52px;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #2b87db;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  outline: none;
}

.tablet-button {
  padding: 2px 15px 4px;
  display: none;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  color: white;
  background-color: #2b87db;
  border: 1px solid #2b87db;
  border-radius: 14px;
}

@media (min-width: 640px) {
  .container {
    margin: 10px 60px 0;
    text-align: initial;
  }

  .banner {
    margin-bottom: 0;
    padding: 24px 15px 24px 24px;
    display: flex;
  }

  .banner-image {
    width: 202px;
    min-width: 202px;
    height: 164px;
    min-height: 164px;
  }

  .content {
    margin: 23px 0 24px 41px;
  }

  .text {
    margin: 11px 47px 27px 0;
  }

  .mobile-button {
    display: none;
  }

  .tablet-button {
    display: inline-block;
  }
}
