.container {
  margin: 20px 0 15px;
  padding: 15px;
  font-size: 14px;
  background: #e4e4e4;
}

.container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.container li {
  display: inline-block;
}

.container li::after {
  padding: 0 6px;
  content: url('./icons/separator.svg');
}

.container li:last-child::after {
  display: none;
}

.container a {
  text-decoration: none;
  color: #7a7a7a;
}
