.container {
  margin: 10px;
}

@media (min-width: 640px) {
  .container {
    margin: 10px 60px;
  }
}

.container-content {
  position: relative;
  padding: 12px 40px 12px 18px;
  display: flex;
  background-color: #e8e9ec;
  border-radius: 4px;
}

.icon {
  margin-top: 2px;
  margin-right: 12px;
  color: var(--decorative-accent-purple);
}

.text {
  margin-bottom: 8px;
}

.button {
  margin: 0;
  padding: 4px 0;
  color: var(--accent-main-primary);
  background: transparent;
  border: none;
}
