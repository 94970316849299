.container {
  all: unset;
  display: flex;
  align-items: center;
}

.media {
  padding-right: 8px;
}

.description {
  display: block;
}

.type {
  display: block;
}
