.thumbnails {
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
}

.thumbnail {
  display: inline-block;
  width: 32.7%;
  height: 68px;
  background-position: center center;
  background-size: cover;
}
