body {
  height: 100%;
  background-color: white;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
