.container {
  position: relative;
  margin: 10px 0;
  content-visibility: auto;
}

@media (min-width: 640px) {
  .container {
    margin: 32px 0;
  }
}
