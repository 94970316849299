.card {
  position: relative;
  padding: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 536px;
  background-color: white;
  border-bottom: 1px solid #e4e9f6;
  align-items: center;
  justify-content: center;
}
