.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
  background-color: white;
  border: 1px solid #c9d1e5;
  border-radius: var(--unit_2, 8px);
}

.photo-block {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 166px;
  background: var(--decorative-theme-white) no-repeat center;
  background-size: cover;
}

.photo-label,
.annotation {
  position: absolute;
  z-index: 1;
}

.photo-label {
  top: var(--unit_4, 16px);
  left: var(--unit_4, 16px);
}

.annotation {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.content {
  padding: var(--unit_4, 16px);
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--unit_2, 8px);
  min-height: 96px;
  justify-content: space-between;
}

.title-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_0, 2px);
}

.logo-container {
  display: flex;
  width: fit-content;
  height: fit-content;
  border: 1px solid #c9d1e5;
  border-radius: var(--unit_1, 4px);
}
