.container {
  margin: 8px 10px;
  padding: 0 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 324px;
  text-decoration: none;
  background-color: white;
  background-image: url('./assets/consultant.svg'), linear-gradient(180deg, #ffe1ff 0%, #c1e8fd 100%),
    linear-gradient(180deg, #c1e8fd 0%, #ffe1ff 100%);
  background-repeat: no-repeat;
  background-position:
    top center,
    top,
    top;
  border-radius: 8px;
}

.title {
  margin: 188px 0 16px;
  max-width: 303px;
  text-align: center;
}

.button {
  margin: auto auto 0;
  max-width: 198px;
}

@media (min-width: 640px) {
  .container {
    margin: 32px 60px;
  }
}
