.container {
  display: block;
  height: 52px;
  font-size: 16px;
  font-weight: bold;
  line-height: 52px;
  text-align: center;
  text-decoration: none;
  color: #2b87db;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
