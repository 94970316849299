.container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_6, 24px);
}

.logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
