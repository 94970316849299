@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.container {
  display: flex;
  background: white;
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: var(--accent-main-primary);
  background: none;
  border: 0;
  outline: none;
  box-shadow: none;
}

.button::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: calc(100% - 10px);
  content: '';
  background: #e4e4e4;
  transform: translate(0, -50%);
}

.button:last-child::after {
  display: none;
}

.button span {
  margin-left: 6px;
}
