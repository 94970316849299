.container {
  position: absolute;
  z-index: 1;
  top: 50%;
  padding: 0;
  display: block;
  width: 24px;
  height: 24px;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
  color: white;
  background: rgba(18, 18, 18, 0.3);
  border: none;
  border-radius: 50%;
  outline: none;
  transform: translate(0, -50%);
}

.container--left {
  left: 10px;
}

.container--right {
  right: 10px;
}
