.features {
  display: flex;
}

.feature {
  position: relative;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 24px;
  height: 24px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

.feature-icon-wrapper {
  position: relative;
  z-index: 1;
  height: 16px;
}
