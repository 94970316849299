.container {
  padding: 4px 10px;
  display: flex;
  justify-content: center;
}

.container > *:not(:last-child) {
  margin-right: 10px;
}

@media (min-width: 640px) {
  .container {
    padding-right: 60px;
    padding-left: 60px;
  }
}
