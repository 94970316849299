.title {
  margin: 20px 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
}

@media (min-width: 640px) {
  .title {
    margin: 20px 60px;
  }
}
