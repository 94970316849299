@import '@cian/ui-kit-design-tokens/colors/semanticPalette.css';

.button {
  padding: 0;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: var(--accent-main-primary);
  background: none;
  border: none;
  align-items: center;

  &:disabled {
    color: var(--gray20_100);
  }

  & > * {
    /* Иконка по умолчанию имеет анимацию цвета, но здесь это не нужно: помимо цвета меняется и текст - с анимацией выглядит плохо */
    transition: none !important; /* stylelint-disable-line declaration-no-important */
  }
}

.text {
  margin-right: 8px;
  text-wrap: nowrap;
}
