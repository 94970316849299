.footer {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: var(--decorative-theme-white);
  border-top: 1px solid var(--gray10_100);
}

.phone-button {
  margin: 0 auto;
  padding-top: 8px;
  display: block;
  width: 290px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  color: currentcolor;
  border: 1px solid currentcolor;
  border-radius: 42px;
  -webkit-tap-highlight-color: transparent;
}

.work-time {
  margin-top: 15px;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: var(--gray60_100);
}

.link {
  margin: 20px auto 0;
  padding-top: 20px;
  display: block;
  width: 280px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  text-decoration: none;
  color: currentcolor;
  border-top: 1px solid var(--gray10_100);
  -webkit-tap-highlight-color: transparent;
}
