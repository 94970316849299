.container {
  margin-top: 10px;
  margin-right: 12px;
  margin-left: 12px;
}

.container > *:not(:empty) ~ .spacer {
  height: 10px;
}

@media (min-width: 640px) {
  .container {
    margin: 32px 60px 22px;
  }
}
