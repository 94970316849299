.container {
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 18px;
}

.container:last-child {
  margin-bottom: 0;
}

.under-construction {
  color: #7a7a7a;
}

.icon {
  position: relative;
  top: 2px;
  margin: 0 8px 0 12px;
  display: inline-block;
}

.icon:first-child {
  margin-left: 0;
}
