.container {
  margin: 10px;
}
@media (min-width: 640px) {
  .container {
    margin: 10px 60px;
  }
}

.container-content {
  position: relative;
  padding: 12px 40px 12px 18px;
  display: flex;
  background-color: #e8e9ec;
  border-radius: 4px;
}

.icon {
  margin-top: 2px;
  margin-right: 14px;
}

.close {
  all: initial;
  position: absolute;
  top: 8px;
  right: 14px;
  padding: 4px;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}
