.button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  cursor: pointer;
  color: white;
  background-color: var(--accent-main-primary);
  border: none;
  border-radius: 50%;
  outline: none;
}
